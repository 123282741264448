<div cdkTrapFocus class="info-modal-container">
  <button
    #closeBtn
    autofocused
    class="close-btn focus-link"
    (click)="bsModalRef.hide()"
    aria-label="Close Terms and Conditions Modal"
  >
    <img src="assets/icons/close.svg" alt="Close modal" />
  </button>
  <div class="info-modal-inner">
    <div class="page-content-modal">
      <h1 tabindex="0" style="text-align: center">
        <strong><span>Terms and Conditions</span></strong>
      </h1>

      <p>Home Captain, Inc. is not a lender or associated with any governmental entity, including the VA.</p>
      <h2 tabindex="0">Eligibility and Access to Our Services</h2>
      <p>
        Home Captain, Inc. encourages you to review this Terms of Use Agreement. Your use of this Website and any of its
        contents or submission of service requests via telephone (collectively &ldquo;the Services&rdquo;) constitutes
        your agreement to all such terms, conditions, and notices in effect at such time. If you do not agree to these
        terms and provisions, you are not permitted to use this site. In order to determine your compliance with this
        Agreement, we may monitor your access and use of our Services in accordance with our Privacy Policy.
      </p>
      <p>By agreeing to these Terms of Use, you represent and warrant that:</p>
      <ol style="list-style-type: lower-alpha">
        <li>You are at least 18 years of age</li>
        <li>You have read these Terms of Use in their entirety</li>
        <li>You have not previously been suspended or removed from the Services</li>
        <li>
          Your registration and your use of the Services is in compliance with all applicable laws and regulations
        </li>
        <li>
          If you are acting on behalf of a company or other entity, you have the authority to bind such company or
          entity
        </li>
      </ol>
      <p>
        To access some features of the Services, you may be required to register for an account. When you register for
        an account, you may be required to provide us with some information about yourself, such as email address or
        other contact information. You agree that the information you provide is accurate and that you will keep it
        accurate and up-to-date at all times. When you register, you may be asked to provide a password. You are solely
        responsible for maintaining the confidentiality of your account and password, and you are responsible for all
        actions taken via your account. You may not share your user account(s) with others. Unless you have entered into
        a commercial agreement with Home Captain, Inc. permitting you to use the Services for transactions on behalf of
        another person, you may only use the Services for transactions on your own behalf, and not on behalf of any
        other person. By accessing Services, you agree that Home Captain, Inc. may share information relating to your
        use of Services with Citibank, N.A., and you understand that Citibank, N.A. intends to use such information to
        market its products and services to you.
      </p>
      <p>Any action by you that, in our sole discretion:</p>
      <ol style="list-style-type: lower-roman">
        <li>Violates the terms and conditions of this Agreement and/or the Privacy Policy</li>
        <li>Restricts, inhibits or prevents any access, use or enjoyment of our services or website</li>
        <li>
          Through the use of our services or website, defames, abuses, harasses, offends or threatens, shall not be
          permitted, and may result in your loss of the right to access and use our services or website. You shall not
          modify, scrape, embed, or frame our services without our prior written permission.
        </li>
      </ol>
      <h3 tabindex="0">Role of Home Captain</h3>
      <p>
        Home Captain, Inc. has a broker license in the state of New York, but Home Captain, Inc. does not: (a) act as a
        real estate agent for you or any other user; (b) represent you or another user in the purchase, sale, or
        exchange of real property, including any negotiation thereof; or (c) lease or rent, offer to lease or rent, or
        negotiate the lease of real property or otherwise aid or assist in the lease of real property. Home Captain,
        Inc. assumes no responsibility for any result or consequence related directly or indirectly to any action or
        inaction that consumers take based on the Services or any other information available through or in connection
        with the Services.
      </p>
      <p>
        Home Captain, Inc. and its Officers may be affiliated with, and serve as board members, directors or officers of
        various affiliated entities (&ldquo;affiliated groups&rdquo;). The use of the Home Captain, Inc. name in any web
        or in any written materials created by any affiliated groups, or any link to the Home Captain, Inc. web site
        maintained on the web site of an affiliated group, does not imply that the affiliated groups are part of Home
        Captain, Inc. Any such links are provided for convenience and informational purposes only. Home Captain, Inc.
        may, from time to time, act as a sponsor for events of affiliated groups, and may purchase advertising or be
        entitled to receive recognition in affiliated group publications as part of a sponsorship.
      </p>
      <p>
        No legal association is intended between Home Captain, Inc. and any affiliated group by virtue of an individual
        board member&rsquo;s involvement with the affiliated group. Unless otherwise expressly stated on the Home
        Captain, Inc. web site or in document approved, written and adopted by Home Captain, Inc. Home Captain, Inc.
        does not endorse, approve, or sponsor any affiliated group. Individual Board members in their individual
        capacity may endorse, approve or sponsor any affiliated group, but such endorsement, approval or sponsorship
        shall not be deemed to bind Home Captain, Inc. or to be on behalf of Home Captain, Inc. or done with the
        permission, consent, authority or ratification of Home Captain, Inc., unless expressly stated otherwise in
        writing by Home Captain, Inc.
      </p>

      <h3 tabindex="0">Information Requests</h3>
      <p>
        When you submit a request for information (an &ldquo;inquiry&rdquo;) through this website, a Home Captain, Inc.
        representative may contact you, most likely by email or at the telephone number you provided.
      </p>
      <p>
        If you, as a consumer, choose to contact a real estate agent or have a real estate agent contact you through the
        services or website by filling out a contact request or other request form on Home Captain, Inc.&rsquo;s
        website, you authorize Home Captain, Inc. to provide your name and contact information and other identifying
        information you provide to the real estate agent you choose. You acknowledge that, by submitting your contact
        request or other request form on Home Captain, Inc.&rsquo;s website, you may receive telemarketing calls from or
        on behalf of the real estate agent at the telephone number(s) you provide.
      </p>
      <p>
        The services or website may provide phone numbers that can connect you with Home Captain, Inc., its service
        providers, or other third parties, such as real estate agents. Some of the numbers listed may be routed through
        a third party service (&ldquo;Calling Service&rdquo;). Calls through the Calling Service may be recorded or
        monitored for quality assurance and customer service purposes. In the event that you make a call to Home
        Captain, Inc. or through a Calling Service, you consent to such recording and monitoring. Home Captain, Inc.
        will treat recorded calls in accordance with its Privacy Policy.
      </p>
      <p>
        If you desire only paper copies of the Communications, if you wish to withdraw your consent to receiving
        electronic Communications, or if you desire paper copies of Communications you have previously received, please
        contact customer service at 1-877-847-4302 or email hello@homecaptain.com so that we may update your information
        accordingly.
      </p>
      <h4 tabindex="0">Electronic Communication</h4>
      <p>
        When you utilize Services through our website, send email to us, or otherwise contact us through electronic
        means, you are communicating with us electronically and consent to receive communications from us via the same
        method(s) or any other method(s). Although we may choose to communicate with you by regular mail, we may also
        choose to communicate with you by email or by posting notices on our Services. You agree that all agreements,
        notices, disclosures and other communications that we provide to you electronically satisfy any legal
        requirement that such communications be in writing.
      </p>
      <h3 tabindex="0">Use of the Services</h3>
      <p>
        As long as you comply with these Terms of Use, Home Captain, Inc. grants you a non-exclusive, limited,
        revocable, personal, non-transferable license to use the Services. If you are a real estate or mortgage
        professional acting in your professional capacity, you may additionally use the Services to provide information,
        to the extent permitted by applicable law, to your clients and to take actions on behalf of your clients
        (&ldquo;Pro Use&rdquo;). If you use the Services for a Pro Use, you represent and warrant that you have obtained
        all required authorizations and consents from your client. Except as expressly stated herein, these Terms of Use
        do not provide you with a license to use, reproduce, distribute, display or provide access to any portion of the
        Services on third-party web sites or otherwise. The Services may include software for use in connection with the
        Services. The Services may not be used for transactions in commercial real estate, which includes, without
        limitation, commercially zoned properties and vacation rentals.
      </p>
      <p>
        Home Captain, Inc. may freely use feedback you provide. You agree that Home Captain, Inc. may use your feedback,
        suggestions, or ideas in any way, including in future modifications of the Services, other products or services,
        advertising or marketing materials. You grant Home Captain, Inc. a perpetual, worldwide, fully transferable,
        sublicensable, non-revocable, fully paid-up, royalty free license to use the feedback you provide to Home
        Captain, Inc. in any way.
      </p>
      <h4 tabindex="0">Prohibited Use</h4>
      <p>
        No person or entity, including without limitation any real estate broker or other real estate professional, may
        market, sell, copy, distribute, redistribute, export, license, transfer, publish, republish, display, link to,
        transmit, retransmit, download, frame, store, post, modify, enter into a database or in any way use or exploit
        the Content or any other portion of the website, in whole or in part, other than in connection with a
        Registrant&rsquo;s potential purchase, sale, development, investment, or lease of an individual property. No
        Registrant or other user of the website may use the Website for any purpose that is prohibited by applicable law
        or these Terms of Use, including without limitation any discriminatory, defamatory or obscene use. Without
        limiting the foregoing, no Registrant or other user of the website (including you) may:
      </p>
      <ol style="list-style-type: lower-alpha">
        <li>
          Take, facilitate, cause or allow any action that imposes an unreasonable or disproportionately heavy load on
          the Website&rsquo;s infrastructure, including without limitation &ldquo;spam&rdquo; or any other such
          unsolicited mass e-mailing techniques;
        </li>
        <li>
          Disclose, use, facilitate, or cause or allow to be disclosed or used, any assigned confirmation numbers, user
          names, and/or passwords for any unauthorized purpose;
        </li>
        <li>Attempt to decipher or reverse engineer all or any portion of the Content;</li>
        <li>
          Use, facilitate or cause or allow to be used any robot, spider, intelligent agent, or other automatic device
          or manual process to search all or any portion of the Content.
        </li>
        <li>
          Reproduce, modify, distribute, display or otherwise provide access to, create derivative works from,
          decompile, disassemble, or reverse engineer any portion of the Services, except as explicitly permitted under
          these Terms of Use;
        </li>
        <li>
          Provide/post/authorize a link to any of the Services (including but not limited to an agent profile page) from
          a third-party website that is not a real estate-related website owned or operated by a real estate or lending
          professional or institution;
        </li>
        <li>Remove or modify any copyright or other intellectual property notices that appear in the Services;</li>
        <li>Use the Services for resale, service bureau, time-sharing or other similar purposes;</li>
        <li>
          Use the Services in any way that is unlawful, or harms Home Captain, Inc., its service providers, suppliers,
          or any other user;
        </li>
        <li>
          Distribute or post spam, chain letters, pyramid schemes, or similar communications through the Services;
        </li>
        <li>Impersonate another person or misrepresent your affiliation with another person or entity;</li>
        <li>
          Reproduce, publicly display, or otherwise make accessible on or through any other Web site, application, or
          service any reviews, ratings, and/or profile information about real estate, lending, or other professionals,
          underlying images of or information about real estate listings, or other data or content available through the
          Services, except as explicitly permitted by Home Captain, Inc. for a particular portion of the Services;
        </li>
      </ol>
      <p>
        No one may use the Website, facilitate or cause or allow it to be used, in violation of any applicable rules
        established by any applicable federal, state, or local law or regulation. Any such action may result in civil
        and/or criminal penalties, and Home Captain, Inc. expressly reserves all of their respective rights, remedies,
        claims and defenses in connection therewith.
      </p>
      <p>
        Your right to access and use our services or website is not transferable by you to any other person or entity.
        You agree to only access and use our services or website for lawful purposes pursuant to the terms and
        conditions of this Agreement and our Privacy Policy .
      </p>
      <p>
        Access and use of our services or website may be interrupted from time to time for any of several reasons,
        including, without limitation, the malfunction of equipment, periodic updating, maintenance or repair of our
        Services or other actions that we, in our sole discretion, may elect to take. We reserve the right to suspend or
        discontinue the availability of our services or website and/or any portion or feature of our services at any
        time in our sole discretion and without prior notice.
      </p>
      <h4 tabindex="0">We Are not a Lender</h4>
      <p>
        Home Captain, Inc. is not a financial institution, insurance provider or other Service Provider of financial
        services and cannot give opinions or recommendations on any specific loan products. We do not, and will not,
        make any coverage or credit decision with any Service Provider referred to you. We do not issue mortgages,
        credit cards, insurance coverage or any other financial products.
      </p>
      <h4 tabindex="0">Links</h4>
      <p>
        This website may contain links to other websites (&ldquo;Linked Sites&rdquo;). The Linked Sites are provided for
        your convenience and information only and, as such, you access them at your own risk. The content of any Linked
        Sites is not under Home Captain, Inc.&rsquo;s control, and Home Captain, Inc. is not responsible for, and does
        not endorse, such content, whether or not Home Captain, Inc. is affiliated with the owners of such Linked Sites.
        You may not establish a hyperlink to this website, provide any links that state, or imply any sponsorship or
        endorsement of your website by Home Captain, Inc., or its affiliates or Providers.
      </p>
      <h4 tabindex="0">Disclaimers</h4>
      <p>
        Home Captain, Inc. works to ensure the accuracy of all of the information on this website. However, errors may
        occur. Home Captain, Inc. reserves the right to edit and/or improve this website at any time. THIS WEBSITE AND
        THE INFORMATION, SOFTWARE, AND SERVICES CONTAINED HEREIN ARE PROVIDED &ldquo;AS IS&rdquo;. HOME CAPTAIN, INC.
        AND ITS PARTICIPATING LENDERS DISCLAIM ANY WARRANTY WHATSOEVER. THE USE OF THIS WEBSITE IS AT YOUR OWN RISK.
        HOME CAPTAIN, INC. AND ITS PARTICIPATING LENDERS ARE NOT LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
        SPECIAL OR CONSEQUENTIAL DAMAGES OR ANY OTHER INJURY ARISING OUT OF THE USE OF THIS WEBSITE. Home Captain, Inc.
        is not responsible for any damages to property, including, but not limited to, Trojan horses, viruses,
        backdoors, worms, trap doors, time bombs, cancelbots, or any other code or computer program that may interfere
        with the transfer of data or contribute to the destruction to property.
      </p>
      <p>
        No Warranties. HOME CAPTAIN, INC. PROVIDES THE SERVICES &ldquo;AS IS,&rdquo; &ldquo;WITH ALL FAULTS&rdquo; AND
        &ldquo;AS AVAILABLE,&rdquo; AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT IS
        WITH YOU. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, HOME CAPTAIN, INC. AND ITS SUPPLIERS MAKE NO
        REPRESENTATIONS, WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED. HOME CAPTAIN, INC. AND ITS SUPPLIERS EXPRESSLY
        DISCLAIM ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS, STATUTORY AND IMPLIED, INCLUDING WITHOUT LIMITATION: (A)
        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, ACCURACY,
        TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND NON-INFRINGEMENT; (B) WARRANTIES OR CONDITIONS ARISING
        THROUGH COURSE OF DEALING OR USAGE OF TRADE; AND (C) WARRANTIES OR CONDITIONS OF UNINTERRUPTED OR ERROR-FREE
        ACCESS OR USE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SERVICES OR ANY
        MATERIALS AVAILABLE THROUGH THE SERVICES WILL CREATE ANY WARRANTY REGARDING ANY HOME CAPTAIN, INC. ENTITY OR THE
        SERVICES THAT IS NOT EXPRESSIVELY STATED IN THESE TERMS OF USE. YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY
        RESULT FROM YOUR USE OF OR ACCESS TO THE SERVICES, YOUR DEALING WITH ANY OTHER USER, AND ANY MATERIALS,
        INCLUDING ALL USER AND HOME CAPTAIN, INC. MATERIALS, AVAILABLE THROUGH THE SERVICES. YOU UNDERSTAND AND AGREE
        THAT YOUR USE OF THE SERVICES, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAINMENT OF MATERIALS THROUGH THE
        SERVICES AND ANY ASSOCIATED SITES OR SERVICES, ARE AT YOUR OWN DISCRETION AND RISK, AND THAT YOU ARE SOLELY
        RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE USED IN CONNECTION
        WITH THE SERVICES), OR THE LOSS OF DATA THAT RESULTS FROM THE USE OF THE SERVICES OR THE DOWNLOAD OR USE OF
        THOSE MATERIALS. SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OR WARRANTIES AND YOU MAY HAVE OTHER RIGHTS THAT
        VARY FROM JURSIDICTION TO JURISDICTION.
      </p>
      <h3 tabindex="0">Copyright, Trademark, and Service Mark Notices</h3>
      <p>
        All contents on the websites are the property of Home Captain, Inc. 1517 Voorhies Avenue; 2n d Floor; Brooklyn,
        New York 11235. Home Captain, Inc. is NOT affiliated with any governmental entity, including the VA. Home
        Captain, Inc. is not a lender but has developed relationships with approved, licensed lenders to support users
        seeking VA and other loan products. We may provide customer information to our trusted service providers to help
        you with your VA or other Loan. Our website contains material that is copyrighted under the United States
        copyright laws. This includes, but is not limited to, any services, graphics, text, videos, software, music,
        photos, or other trademarked items on Home Captain, Inc. You may not publish, modify copy, transmit, exploit, or
        participate in the transfer or sale of any created works in whole or in part in any manner unless it is
        permitted under copyright law. All software used on or within our Services (the &ldquo;Software&rdquo;) is our
        property or the property of our software vendors and is protected by United States and international copyright
        laws. Viewing, reading, printing, downloading or otherwise using the Home Captain&rsquo;s Content and/or the
        Collective Work does not entitle you to any ownership or intellectual property rights to the Content, the
        Collective Work, or the Software.
      </p>
      <p>
        You are solely responsible for any damages resulting from your infringement of our or any third-party&rsquo;s
        intellectual property rights regarding the Trademarks, the Content, the Collective Work, the Software and/or any
        other harm incurred by us or our affiliates as a, direct or indirect, result of your copying, distributing,
        redistributing, transmitting, publishing or using the same for purposes that are contrary to the terms and
        conditions of this Agreement.
      </p>
      <p>
        We grant you a limited license to access, print, download or otherwise make personal use of the Content and the
        Collective Work in the form of: (i) one machine-readable copy; (ii) one backup copy; and (iii) one print copy,
        for your non-commercial use; provided, however, that you shall not delete any proprietary notices or materials
        with regard to the foregoing manifestations of the Content and the Collective Work. You may not modify the
        Content or the Collective Work or utilize them for any commercial purpose or any other public display,
        performance, sale, or rental, decompile, reverse engineer, or disassemble the Home Captain&#39;s Content and the
        Collective Work, or transfer the Content or the Collective Work to another person or entity.
      </p>
      <p>
        Except as otherwise permitted under the copyright laws of the United States, no other copying, distribution,
        redistribution, transmission, publication or use, other than the non-commercial use of the Content and the
        Collective Work as permitted by this Agreement, is permitted by you without our prior written permission.
      </p>
      <p>
        You may not use any meta tags or any other &ldquo;hidden text&rdquo; utilizing our name or trademarks without
        our prior written permission.
      </p>
      <h4 tabindex="0">Choice of Law and Dispute Resolution</h4>
      <p>
        These Terms of Use are governed by the laws of the State of New York, without giving effect to its conflict of
        law provisions. Any claim arising out of or related to the use of Home Captain, Inc.&rsquo;s products and
        services shall be resolved through arbitration. The arbitration shall be held before an arbitrator under the
        commercial arbitration rules of the American Arbitration Association. The arbitration shall take place in the
        state of New York. These terms and conditions provide that all disputes shall be resolved by binding
        arbitration. You, the consumer, forfeit all rights to go to court to defend or assert your rights. You, the
        consumer also forfeit your rights to bring class action lawsuits against Home Captain, Inc. By using the goods
        or services provided by Home Captain, Inc., you give consent to these restrictions.
      </p>
      <p>
        You acknowledge that we may be irreparably damaged if this Agreement is not specifically enforced, and damages
        at law would be an inadequate remedy. Therefore, in the event of a breach or threatened breach of any provision
        of this Agreement by you, we shall be entitled, in addition to all rights and remedies, to an injunction
        restraining such breach or threatened breach, without being required to show any actual damage or to post an
        injunction bond, and/or to a decree for specific performance of the provisions of this Agreement. For purposes
        of this Section, you agree that any action or proceeding with regard to such injunction restraining such breach
        or threatened breach shall be brought in the courts of record of New York, New York, or the United States
        District Court, Eastern District of New York. You consent to the jurisdiction of such court and waive any
        objection to the laying of venue of any such action or proceeding in such court. You agree that service of any
        court paper may be affected on such party by mail or in such other manner as may be provided under applicable
        laws, rules of procedure or local rules.
      </p>
      <p>
        Notice to California Residents. If you are a California resident, under California Civil Code Section 1789.3,
        you may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department
        of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by
        telephone at (800) 952-5210 in order to resolve a complaint regarding the Service or to receive further
        information regarding use of the Service.
      </p>
      <h4 tabindex="0">Indemnification</h4>
      <p>
        You agree to indemnify Home Captain, Inc. and its participating lenders from and against any and all liabilities
        resulting in use by you of the site outside of that expressly outlined in the Terms and Conditions including any
        claims alleging facts that if accurate would equal a breach by you of this Terms of Use Agreement.
      </p>
      <h4 tabindex="0">Errors and Delays</h4>
      <p>
        Home Captain, Inc. is not liable for delays or errors in responding to your any request that are a result of
        inaccurate information you provide, or any other reason beyond Home Captain, Inc.&rsquo;s reasonable control.
      </p>
      <h4 tabindex="0">Additional Terms</h4>
      <p>
        This agreement between you, the consumer, and Home Captain, Inc. supersedes all promises, proposals, or prior
        communications, whether written, oral, or electronic. This agreement shall be governed to the laws of the state
        of New York. All rights not expressly granted are reserved.
      </p>
      <p>
        We reserve the right to update, amend and/or change this Agreement at any time in our sole discretion and
        without notice. Updates to this Agreement will be posted here. Amendments will take effect immediately upon us
        posting the updated Agreement on our website. You are encouraged to revisit this Agreement from time to time in
        order to review any changes that have been made. The date on which this Agreement was last updated will be noted
        immediately above this Agreement. Your continued access and use of our Services following the posting of any
        such changes shall automatically be deemed your acceptance of all changes.
      </p>
      <p>Please Retain for Your Records a Printed Copy of this Agreement.</p>
    </div>
  </div>
</div>
